import React, { Component } from "react"
import CommonHeader from "./CommonHeader"
import { ReadingContents } from "./ReadingContents"
import css from "./stylesheets/LayoutVenue.module.scss"

class LayoutReadings extends Component {
  // 表示サイズ領域を 1000px とした場合の換算を行います。
  // sketch や XD で幅 1000px のキャンバスに自由レイアウトされた状態をウェブサイト側での vw の単位に変換します。
  static convertToVw = (imageWidth, scale = 0.5, baseWidth = 1000) => {
    return (imageWidth / baseWidth) * scale * 100 + "vw"
  }

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = {
      isSpMode: null,
    }
    this._spChecker = React.createRef()
  }

  /**
   *
   */
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.handleWindowResize = e => {
        this.doResize()
      }
      window.addEventListener("resize", this.handleWindowResize, true)
    }
    setTimeout(() => {
      this.doResize()
    }, 1)
  }

  /**
   *
   */
  doResize() {
    if (window) {
      try {
        const isSpMode =
          document.defaultView.getComputedStyle(this._spChecker.current, null)
            .display !== `none`

        this.setState(state => ({
          isSpMode: isSpMode,
        }))
      } catch (e) {}
    }
  }

  render() {
    const style = {
      backgroundColor: this.props.bgColor ? this.props.bgColor : 'transparent',
    }

    const textColorClass = this.props.colorMode === `#ffffff` ? `is-white-text` : ``

    return (
        <div style={style} className={css.base + ` ` + textColorClass}>
        <CommonHeader colorMode={this.props.colorMode} />
        <div className={css.contentBox}>
          <ReadingContents
            isSpMode={this.state.isSpMode}
            readings={this.props.readings}
          />
        </div>
        <div className={`onlySp`} ref={this._spChecker} />
      </div>
    )
  }
}

export default LayoutReadings
