import React from "react"
import LayoutReadings from "../components/LayoutReadings"
import {graphql} from "gatsby";
import Seo from "../components/Seo";

export const pageQuery = graphql`
    query($slug: String!) {
        wpPage(slug: {eq: $slug}) {
            title
            slug
            acfReadings {
                fieldGroupName
                readings {
                    ... on WpPage_Acfreadings_Readings_Headline1 {
                        fieldGroupName
                        headline1Text
                        headline1IsItalic
                    }
                    ... on WpPage_Acfreadings_Readings_Headline2 {
                        fieldGroupName
                        headline2Text
                        headline2IsItalic
                    }
                    ... on WpPage_Acfreadings_Readings_Paragraph {
                        fieldGroupName
                        paragraphText
                    }
                    ... on WpPage_Acfreadings_Readings_Figure {
                        fieldGroupName
                        figureImage {
                            mediaItemUrl
                            caption
                        }
                        left
                        leftSp
                        top
                        topSp
                        width
                    }
                    ... on WpPage_Acfreadings_Readings_Embed {
                        embedUrl
                        fieldGroupName
                    }
                    ... on WpPage_Acfreadings_Readings_Spacer {
                        fieldGroupName
                        spacer
                    }
                }
            }
            acfPageSetting {
                color
                bgColor
            }
        }
    }
`

export default (props) => {
    const { title, slug, acfReadings, acfPageSetting } = props.data.wpPage

    return(
        <div className={slug}>
            <LayoutReadings
                bgColor={acfPageSetting.bgColor}
                colorMode={acfPageSetting.color}
                readings={acfReadings.readings}
            >
                <div>
                    <Seo lang={`en`} title={title} />
                </div>
            </LayoutReadings>
        </div>
    )
}
